import React from "react"
import Layout from "../components/layout"
import Input from "../components/Atoms/input"
import Button from "../components/Atoms/button"
import Fade from "react-reveal/Fade"


import contact from "../images/contact1.webp"

const Contact = () => {
  const handleEmailClick = () => {
    window.location.href = "mailto:help@orangethink.com.hk";
  };
  return (
    <Layout>
      <Fade bottom cascade>
        <div className="max-w-7xl mx-auto mt-10 flex text-white xxs:flex-col sm:flex-row">
          <div className="bg-lightblack p-10 rounded-xl w-3/4 m-5">
            <h2 className="text-xs opacity-50">Get in touch</h2>
            <h1 className="text-4xl font-bold font-poppins mt-2 xxs:text-lg sm:text-2xl lg:text-4xl">
              Help
            </h1>
            <p className="text-lg mt-2 opacity-50 w-3/4 xxs:text-xs xxs:w-full sm:text-sm sm:w-3/4">
              Have a question about our products? Need assistance with a feature or experiencing an issue? Our team is always ready to support you. Don’t hesitate to reach out — we’re here to ensure you get the most out of our services.
            </p>
            <p className="text-lg mt-2 opacity-50 w-3/4 xxs:text-xs xxs:w-full sm:text-sm sm:w-3/4">
              Whether it’s troubleshooting a problem, understanding how to use a particular tool, or simply needing more information, we’re just an email away. Your satisfaction is our top priority, and we’re committed to providing timely and effective solutions.
            </p>
            <p className="text-lg mt-2 opacity-50 w-3/4 xxs:text-xs xxs:w-full sm:text-sm sm:w-3/4">
              Please send your inquiries or requests for help to <strong>help@orangethink.com.hk</strong>. We’ll get back to you as soon as possible and work with you to resolve any issues or provide the guidance you need.
            </p>

            <Button
              title="Send us an email"
              colorClass="bg-gradient-to-r from-pink to-purple"
              marginClass="mt-5"
              onClick={handleEmailClick}
            ></Button>
          </div>
          <div className="w-1/4 overflow-hidden rounded-xl m-5 xxs:hidden sm:block">
            <img
              className="object-cover h-full w-full"
              src={contact}
            ></img>
          </div>
        </div>
      </Fade>
    </Layout>
  )
}

export default Contact
